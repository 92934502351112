import React, { useContext } from 'react';
import logo from '../assets/images/logo.png';
import { MobileContext } from './Layout';
import { DesktopMenu, MobileMenu } from './Menu';
import PatientenLogin from './PatientenLogin';

export default function Header() {
  const isMobile = useContext(MobileContext);

  if (isMobile) {
    return (
      <header data-header>
        <a href="/">
          <div className="logo">
            <img width={62} height={60} src={logo} alt="" />
          </div>
        </a>
        <MobileMenu />
      </header>
    );
  } else {
    return (
      <header data-header>
        <div>
          <PatientenLogin />
          <div className="main-container">
            <a href="/">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </a>
            <DesktopMenu />
          </div>
        </div>
      </header>
    );
  }
}
