import React, { useContext, useState } from 'react';
import arrowDown from '../assets/icons/arrow_down.svg';
import arrowUp from '../assets/icons/arrow_up.svg';
import sanofiLogo from '../assets/images/footer-logos.png';

import { Link } from 'gatsby';
import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import {MatNrContext, MobileContext} from './Layout';
import Popup from './Popup';

export default function Footer() {

  const isMobile = useContext(MobileContext);
  const {matNr} = useContext(MatNrContext);

  return (
    <div className="footer-top-accordion-container">
      <Container className="p-0" style={{ backgroundColor: 'white' }}>
        <Accordion className="footer-top-accordion" defaultActiveKey="0">
          <Card className="border-0">
            <Card.Header
              className="border-0"
              style={{ padding: '0', backgroundColor: 'white' }}>
              <CustomToggle />
            </Card.Header>
            <Accordion.Collapse className="borderPad">
              <ListGroup variant="flush">
                <h5 className="accordion-sub-heading">
                  Was ist Neurodermitis?
                </h5>
                <p className="accordion-sub-heading-text">
                  Neurodermitis ist eine chronisch-entzündliche Hauterkrankung,
                  die meist in Schüben auftritt und sich durch sichtbare
                  Hautveränderungen und starken Juckreiz äußert. Betroffene
                  fühlen sich in vielen Lebensbereichen häufig stark
                  eingeschränkt.
                </p>
                <h5 className="accordion-sub-heading">
                  Was sind die genauen Ursachen und Symptome der Neurodermitis
                  (atopische Dermatitis) und was können mögliche Auslöser sein?{' '}
                </h5>
                <p className="accordion-sub-heading-text">
                  Juckreiz, Kratzen, Schlaflosigkeit – für Betroffene mit
                  schweren Symptomen dreht sich jeder Tag darum, wie sie ihr
                  Leiden lindern können. Jeder Schub belastet Betroffene auf
                  körperlicher und mentaler Ebene. Beruf und Alltagsleben können
                  oft nur eingeschränkt bewältigt werden. Es ist daher für
                  Betroffene sehr wichtig, persönliche Strategien zum Umgang mit
                  der Erkrankung zu entwickeln.{' '}
                </p>
                <h5 className="accordion-sub-heading">
                  Neurodermitis kennenlernen
                </h5>
                <p className="accordion-sub-heading-text">
                  Wer seine Haut kennt, Routine in der Behandlung und Pflege
                  entwickelt und auf seine persönlichen Bedürfnisse achtet, tut
                  bereits viel dafür, um die Neurodermitis zu kontrollieren.
                  Unsere Lernmodule unterstützen Dich dabei, Dein Leben mit
                  Neurodermitis aktiv zu gestalten.{' '}
                </p>
                <h5 className="accordion-sub-heading">
                  Behandlungsmöglichkeiten
                </h5>
                <p className="accordion-sub-heading-text">
                  Neurodermitis kann heute mit modernen Therapien gezielt
                  behandelt werden. Eine individuelle Behandlung ist oft der
                  erste Schritt hin zu mehr Selbstwertgefühl.
                </p>
                <h5 className="accordion-sub-heading">Neurodermitis-Blogger</h5>
                <p className="accordion-sub-heading-text">
                  Was im Alltagsleben mit Neurodermitis wirklich hilft und
                  wichtig ist, wissen sie am besten – unsere Blogger. Auf der
                  Website „Leben mit Neurodermitis“ erfährst Du, was andere
                  Betroffene erlebt haben, was sie im Leben mit Neurodermitis
                  gelernt haben und was ihnen Mut macht. Gestalte auch Dein
                  Leben – trotz Neurodermitis.
                </p>
                <ListGroup.Item
                  className="list-item"
                  style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}>
                  <h4
                    style={{
                      fontSize: '14px',
                      color: ' #302156',
                      fontWeight: 500,
                      lineHeight: 1.29,
                      borderTop: '1px solid #633bb0',
                    }}>
                    Die hier zur Verfügung gestellten medizinischen
                    Informationen dienen ausschließlich allgemeinen
                    Informationszwecken. Bei allen Fragen zu Deiner Erkrankung
                    wende Dich bitte an Deinen Dermatologen.
                  </h4>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
      <footer className={`footer`}>
        <div className={`${isMobile ? '' : 'desktop-padding'} upper-container`}>
          <p
              className={`${
                  isMobile ? 'mobile-footer-text' : 'desktop-footer-text'
              }`}>
            * Allein aus Gründen der besseren Lesbarkeit wurde auf die
            gleichzeitige Verwendung geschlechtsspezifischer Sprachformen
            verzichtet. Sämtliche Personenbezeichnungen gelten aber
            selbstverständlich für alle Geschlechter.
            <br/>
            Für unsere Webseiten wurden Bilder von Adobe Stock, Canva, Getty Images, Shutterstock und Unsplash
            verwendet.
          </p>
          <div className={`${isMobile ? 'mobile-footer-links' : ''} links`}>
            <a
                href="https://www.sanofi.de/" target="_blank"
                style={{
                  paddingLeft: '0px',
                }}>
              Sanofi.de
            </a>
            <a href="https://www.sanofi.de/de/impressum" target="_blank">Impressum</a>
            <a href="https://www.sanofi.de/de/kontakt" target="_blank">Kontakt</a>
            <a href="https://www.sanofi.de/de/nutzungsbedingungen" target="_blank">
              Nutzungsbedingungen
            </a>
            <a
                href="https://www.sanofi.de/datenschutz/SADG" target="_blank"
                style={{
                  borderRight: 'none',
                }}>
              Datenschutz
            </a>
          </div>
        </div>
        <div
            className={`${isMobile ? '' : 'desktop-padding'} bottom-container`}>
          <div
              className="copyright-container"
              style={{display: 'flex', flexDirection: 'column'}}>
            <p className={`${isMobile ? 'pb-1' : 'pt-3'}`}>
              Copyright @ 2024 Sanofi-Aventis Deutschland GmbH. Alle Rechte
              vorbehalten.
            </p>
            <p className={`${isMobile ? '' : 'pt-3 pb-3'}`}>
              {matNr ?? 'MAT-DE-2301844-1.0-05/2023'}
            </p>
          </div>
          <div
              className={`${isMobile ? 'p-2' : 'p-3'}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontWeight: '700',
              }}>
            <img
                src={sanofiLogo}
                className="sanofi-logo"
              alt="SANOFI | REGENERON"></img>
          </div>
        </div>
        <Popup />
      </footer>
    </div>
  );
}

function CustomToggle({ eventKey }) {
  const [open, openAccordion] = useState(false);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    openAccordion(!open);
  });

  useEffect(() => {
    const footerElement =
      document.getElementsByClassName('footer-accordion')[0];
    footerElement.style.borderWidth = open ? '1px' : '0px';
  });

  return (
    <div className="footer-accordion">
      <div className="mt-3 mb-3 arrow-wrapper" onClick={decoratedOnClick}>
        {open && (
          <img
            className="arrow-image"
            src={arrowUp}
            onClick={decoratedOnClick}
            alt="Arrow Up Icon"
          />
        )}
        {!open && (
          <img
            className="arrow-image"
            src={arrowDown}
            onClick={decoratedOnClick}
            alt="Arrow Down Icon"
          />
        )}
      </div>
      <div className="text-start mt-3 mb-3" style={{ color: '#633bb0' }}>
        <h3 className="fs-20">
          LEBEN MIT NEURODERMITIS. INFORMATIONEN FINDEST DU HIER.
        </h3>
      </div>
    </div>
  );
}
