import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export default function ResponsiveImage({
  imgName,
  altImg,
  className,
  style,
  loading,
  imgStyle
}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 85
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `);

  if (!data) return;

  const image = getGatsbyImage(imgName, data.allFile);

  return (
    <GatsbyImage
      loading={loading ? loading : 'lazy'}
      className={className ? className : ''}
      image={image}
      alt={altImg ? altImg : ''}
      style={style}
      imgStyle={imgStyle}
    />
  );
}

function getGatsbyImage(imageName, allImages) {
  if (!allImages) return;

  let edge = allImages.edges.filter((node) => node.node.name === imageName)[0];
  if (edge) {
    return getImage(edge.node.childImageSharp);
  }
}
